"use client";
import { jsx } from "react/jsx-runtime";
import { useMemo, forwardRef } from "react";
import { useContainerWidth } from "./hooks.js";
import StaticPhotoAlbum from "../static/index.js";
import resolveRowsProps from "./rowsProps.js";
import computeRowsLayout from "../layouts/rows.js";
function RowsPhotoAlbum({ photos, breakpoints, defaultContainerWidth, ...rest }, ref) {
  const { containerRef, containerWidth } = useContainerWidth(ref, breakpoints, defaultContainerWidth);
  const { spacing, padding, targetRowHeight, minPhotos, maxPhotos, ...restProps } = resolveRowsProps(containerWidth, {
    photos,
    ...rest
  });
  const model = useMemo(
    () => containerWidth !== void 0 && spacing !== void 0 && padding !== void 0 && targetRowHeight !== void 0 ? computeRowsLayout(photos, spacing, padding, containerWidth, targetRowHeight, minPhotos, maxPhotos) : void 0,
    [photos, spacing, padding, containerWidth, targetRowHeight, minPhotos, maxPhotos]
  );
  return jsx(StaticPhotoAlbum, { layout: "rows", ref: containerRef, model, ...restProps });
}
const RowsPhotoAlbum$1 = forwardRef(RowsPhotoAlbum);
export {
  RowsPhotoAlbum$1 as default
};
