import Logo from "../../assets/img/svg/logo.svg";
import Navigation from '../../Navigation';
import { useState, useEffect, useCallback, useRef } from "react";
import { BlobServiceClient } from '@azure/storage-blob';
import ShortUniqueId from 'short-unique-id';
import axios from "axios";
import { CheckCircle } from "lucide-react";

const query = `
{
  navigationCollection {
    items {
      home
      offerte
      formule
      gallerij
      contact
    }
  }
  formuleCollection(order: order_ASC) { 
    total 
    items { 
      productTitle 
      offerCode
    } 
  }
}
`;

const Offerte = () => {
  const [page, setPage] = useState('');
  const [isDev, setIsDev] = useState(0);
  const [mainUid, setUid] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const uid = new ShortUniqueId({ length: 10 });
  const [selectedFormulasList, setSelectedFormulasList] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [data, setData] = useState({
    quoteDate: "2025-03-21",
    clientName: "",
    clientEmail: "",
    clientPhone: "",
    clientAddress: "",
    clientCity: "",
    clientPostalCode: "",
    clientVatNumber: "",
    location: "",
    numberOfGuests: 50,
    numberOfChildren: 5,
    numberOfToddlers: 2,
    eventDate: "",
    typeOfEvent: "",
    eventTime: "18:00",
    eatingTime: "19:30",
    comments: "",
    formulaLines: [],
  });
  const connectionString = process.env.AZURE_STORAGE_PROD_STRING;
  let containerName = process.env.AZURE_STORAGE_CONTAINER_PROD_NAME;
  let accountName = process.env.AZURE_STORAGE_ACCOUNT_PROD_NAME;
  let sasToken = process.env.AZURE_SAS_PROD_TOKEN; // SAS token without the leading '?'
  if (isDev == 1) {
    containerName = process.env.AZURE_STORAGE_CONTAINER_NAME;
    accountName = process.env.AZURE_STORAGE_ACCOUNT_NAME;
    sasToken = process.env.AZURE_SAS_TOKEN;
  }
  const [filteredFormulas, setFilteredFormulas] = useState([]);

  useEffect(() => {
    if (page.formuleCollection) {
      setFilteredFormulas(page.formuleCollection.items);
    }
  }, [page]);

  // Function to get the Blob Service Client
  const getBlobServiceClient = () => {
    if (!accountName || !sasToken) {
      throw new Error('Azure Storage account name or SAS token is missing in environment variables.');
    }
    const blobServiceUrl = `https://${accountName}.blob.core.windows.net`;
    return new BlobServiceClient(`${blobServiceUrl}?${sasToken}`);
  };

  const handleUploadJson = async (data) => {
    try {
      const mockData = {
        quoteDate: "2025-03-21",
        clientName: "Bander Soonne",
        clientEmail: "Bander.Soonne@example.com",
        clientPhone: "+32 491 123456",
        clientAddress: "Stationstraat 10",
        clientCity: "Gent",
        clientPostalCode: "9000",
        clientVatNumber: "BE0123456789",
        location: "Feestzaal De Kroon, Markt 1, Brugge",
        numberOfGuests: 50,
        numberOfChildren: 5,
        numberOfToddlers: 2,
        eventDate: "2026-06-15",
        eventTime: "18:00",
        eatingTime: "19:30",
        comments: "Gasten hebben voorkeur voor vegetarische opties",
        formulaLines: [
          {
            formula: [
              {
                formule:
                  "Menu 5: Aperitief hapjes (5 stuks), Frietjes + brochette, saus naar keuze",
                code: "GGM5",
              },
            ],
            quantity: 50,
          },
        ],
      };
  
      const blobServiceClient = getBlobServiceClient();
      const containerClient = blobServiceClient.getContainerClient(containerName);
  
      const blobName = `quote-${mainUid}.json`;
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
  
      // Convert object to JSON string
      const jsonString = JSON.stringify(data, null, 2);
      const jsonBuffer = new TextEncoder().encode(jsonString);
  
      await blockBlobClient.upload(jsonString, jsonString.length, {
        blobHTTPHeaders: {
          blobContentType: "application/json",
        },
      });

      // Force content-type update
      await blockBlobClient.setHTTPHeaders({ blobContentType: "application/json" });  
      console.log(`✅ File uploaded successfully: ${blockBlobClient.url}`);
    } catch (error) {
      console.error("❌ Error uploading JSON data:", error);
    }
  };

  useEffect(() => {
    setUid(uid.rnd());
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.CONTENTFUL_DELIVERY_TOKEN}`,
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        setPage(data);
      });
  }, []);

  const handleQuantityChange = (index, quantity) => {
    setSelectedFormulasList(prevList => 
      prevList.map((formula, i) => 
        i === index ? { ...formula, quantity } : formula
      )
    );
  
    setData(prevData => ({
      ...prevData,
      formulaLines: prevData.formulaLines.map((line, i) => 
        i === index ? { ...line, quantity } : line
      )
    }));
  };  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'selectedFormulas') {
      let selectedValues = e.target.value;
      if (!Array.isArray(selectedValues)) {
        console.warn("Expected an array but got:", selectedValues);
        return;
      }

      const totalQuantity = parseInt(data.numberOfGuests || 0, 10) + parseInt(data.numberOfChildren || 0, 10);
      
      const newSelectedFormulas = selectedValues.filter(item => 
        item && item.productTitle && item.offerCode &&
        !selectedFormulasList.some(existing => existing.name === item.productTitle)
      ).map(item => ({
        name: item.productTitle,
        quantity: totalQuantity
      }));
  
      if (newSelectedFormulas.length > 0) {
        setSelectedFormulasList(prevList => [...prevList, ...newSelectedFormulas]);
        setData(prev => ({
          ...prev,
          formulaLines: [...(prev.formulaLines || []), ...newSelectedFormulas.map(item => ({
            formula: item.name,
            quantity: totalQuantity
          }))]
        }));
      }
    } else {
      setData(prev => ({ ...prev, [name]: value }));
    }
  };  

  const handleSubmit = (e) => {
    e.preventDefault();

    // Recalculate totalQuantity for each formula
    const totalQuantity = parseInt(data.numberOfGuests || 0, 10) + parseInt(data.numberOfChildren || 0, 10);
    const updatedFormulaLines = data.formulaLines.map((line) => ({
      ...line,
      quantity: totalQuantity,
    }));

    // Update the data state with recalculated formulaLines
    const updatedData = {
      ...data,
      formulaLines: updatedFormulaLines,
    };

    setData(updatedData);
    // Add your submission logic here
    setFormSubmitted(true);
    //console.log(updatedData);
    handleUploadJson(updatedData);
  };

  if (!page) {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>Loading...</p>
            </div>
          </div>
        </div>
      </>
    )
  }

  if (window.localStorage.getItem('adminBoefferEmile')) {
    return (
      <main>
        <div className="container">
          <div className="row align-items-center pb-xlarge">
            <div className="col-12 col-md-6">
              <a href="/">
                <figure>
                  <Logo className="site-logo" />
                </figure>
              </a>
              </div>
              <div className="col-12 col-md-6">
                <Navigation
                  navProps={page.navigationCollection.items[0]}
                ></Navigation>
              </div>
            </div>
            <div className="row pb-xlarge">
              <div className="col-12">
                  <div className="card">
                      <div className="card__content">
                          <h2>Maak hier jou offerte!</h2>
                          <form onSubmit={handleSubmit}>
                          {/* Client Information Section */}
                          <div className="mb-4">
                            <h4 className="mb-3 border-bottom pb-2">Klantgegevens</h4>
                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <label className="form-label">Naam of bedrijfsnaam *</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="clientName"
                                  value={data.clientName}
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label">Email *</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  name="clientEmail"
                                  value={data.clientEmail}
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <label className="form-label">Telefoonnummer *</label>
                                <input
                                  type="tel"
                                  className="form-control"
                                  name="clientPhone"
                                  value={data.clientPhone}
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label">BTW Nummer</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="clientVatNumber"
                                  value={data.clientVatNumber}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <label className="form-label">Adres</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="clientAddress"
                                  value={data.clientAddress}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="col-md-2 mb-3">
                                <label className="form-label">Postcode</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="clientPostalCode"
                                  value={data.clientPostalCode}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label className="form-label">Gemeente</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="clientCity"
                                  value={data.clientCity}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          {/* Event Details Section */}
                          <div className="mb-4">
                            <h4 className="mb-3 border-bottom pb-2">Evenementsdetails</h4>
                            <div className="row">
                              <div className="col-md-4 mb-3">
                                <label className="form-label">Datum *</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="eventDate"
                                  value={data.eventDate}
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label className="form-label">Starttijd feest *</label>
                                <input
                                  type="time"
                                  className="form-control"
                                  name="eventTime"
                                  value={data.eventTime}
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                              <div className="col-md-4 mb-3">
                                <label className="form-label">Eettijd feest</label>
                                <input
                                  type="time"
                                  className="form-control"
                                  name="eatingTime"
                                  value={data.eatingTime}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-2 mb-3">
                                <label className="form-label">Aantal volwassenen *</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="numberOfGuests"
                                  value={data.numberOfGuests}
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                              <div className="col-md-2 mb-3">
                                <label className="form-label">Aantal kinderen</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="numberOfChildren"
                                  value={data.numberOfChildren}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="col-md-2 mb-3">
                                <label className="form-label">Aantal peuters</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="numberOfToddlers"
                                  value={data.numberOfToddlers}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label">Soort feest</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="typeOfEvent"
                                  value={data.typeOfEvent}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <div className="mb-3">
                              <label className="form-label">Locatie *</label>
                              <input
                                type="text"
                                className="form-control"
                                name="location"
                                value={data.location}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>

                          {/* Formula Section */}
                          <div className="mb-4">
                            <h4 className="mb-3 border-bottom pb-2">Menu Formule</h4>
                            <div className="mb-3">
                              <div className="d-flex">
                                <div className="col-md-6 mb-3">
                                  <label htmlFor="formulaSelect" className="form-label">Selecteer formules</label>
                                </div>
                                <div className="col-md-6 mb-3">
                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    placeholder="Zoek formules..."
                                    onChange={(e) => {
                                      const searchTerm = e.target.value.toLowerCase();
                                      setFilteredFormulas(
                                        page.formuleCollection.items.filter((item) =>
                                          item.productTitle.toLowerCase().includes(searchTerm)
                                        )
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <select 
                                multiple 
                                className="form-select" 
                                id="formulaSelect" 
                                name="selectedFormulas"
                                value={data.selectedFormulas || []}
                                onChange={(e) => {
                                  const selectedOptions = Array.from(e.target.selectedOptions, (option) => ({
                                    productTitle: option.text,
                                    offerCode: option.value,
                                  }));
                                  handleInputChange({
                                    target: {
                                      name: 'selectedFormulas',
                                      value: selectedOptions,
                                    },
                                  });
                                }}
                              >
                                {filteredFormulas.map((item, index) => (
                                  <option key={index} value={item.offerCode}>
                                    {item.productTitle}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {data.selectedFormulas && data.selectedFormulas.length > 0 && (
                              <div className="mt-3">
                                <h5>Geselecteerde formules:</h5>
                                <ul className="list-group">
                                  {data.selectedFormulas.map((formula, index) => (
                                    <li key={index} className="list-group-item">
                                      {formula.productTitle} ({formula.offerCode})
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>

                          <div className="selected-formulas">
                            <h4 className="mb-4 border-bottom pb-2">Geselecteerde Formules</h4>
                            {selectedFormulasList.map((formula, index) => (
                              <div key={index} className="formula-item">
                                <span>{formula.name}</span>
                              </div>
                            ))}
                          </div>


                          {/* Comments Section */}
                          <div className="mb-4">
                            <h4 className="mb-3 border-bottom pb-2">Opmerkingen</h4>
                            <textarea
                              className="form-control"
                              rows="4"
                              name="comments"
                              value={data.comments}
                              onChange={handleInputChange}
                              placeholder="Speciale verzoeken of dieetwensen..."
                            ></textarea>
                          </div>

                          <div className="d-grid gap-2">
                          {formSubmitted ? (
                            <div className="alert alert-success p-4 d-flex align-items-center text-success fw-bold">
                              <p>Aangevraagd <CheckCircle className="ms-2" /></p>
                            </div>
                          ) : (
                            <button type="submit" className="btn btn-primary btn-lg">
                              Offerte Aanvragen
                            </button>
                          )}
                          </div>
                        </form>
                      </div>
                  </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
              <footer className='footer'>
                <div>
                    <p>&copy; 2025 Boefferke. All rights reserved.</p>
                </div>
                
                <div className='d-flex'>
                    <ul className='menu-list'>
                        <li><a href="https://boefferke.be/public_files/Algemene-voorwaarden.pdf">Algemene voorwaarden</a></li>
                    </ul>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </main>
    )
  }

  return (
    <main>
      <div className="container">
        <div className="row align-items-center pb-xlarge">
          <div className="col-12 col-md-6">
            <a href="/">
              <figure>
                <Logo className="site-logo" />
              </figure>
            </a>
            </div>
            <div className="col-12 col-md-6">
              <Navigation
                navProps={page.navigationCollection.items[0]}
              ></Navigation>
            </div>
          </div>
          <div className="row pb-xlarge">
            <div className="col-12">
                <div className="card">
                    <div className="card__content">
                        <h2>De offerte tool komt eraan!</h2>
                    </div>
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
            <footer className='footer'>
              <div>
                  <p>&copy; 2025 Boefferke. All rights reserved.</p>
              </div>
              
              <div className='d-flex'>
                  <ul className='menu-list'>
                      <li><a href="https://boefferke.be/public_files/Algemene-voorwaarden.pdf">Algemene voorwaarden</a></li>
                  </ul>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </main>
  )
}
export default Offerte
  