import Logo from "../../assets/img/svg/logo.svg";
import Navigation from '../../Navigation';
import { useState, useEffect, useCallback, useRef } from "react";
import Aos from 'aos';
import { MasonryPhotoAlbum } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import { RowsPhotoAlbum } from "react-photo-album";
import Video from "yet-another-react-lightbox/dist/plugins/video";

const query = `
{
  navigationCollection {
    items {
      home
      offerte
      formule
      gallerij
      contact
    }
  }
  gallerijFotoCollection {
    items {
      gallerijFotoTitel
      afbeelding1
    }
  }
}
`;

const GalleryPage = ({ formuleSoort, parentState, parentStateSetter }) => {
  const [page, setPage] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [index, setIndex] = useState(-1);

  Aos.init();

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.CONTENTFUL_DELIVERY_TOKEN}`,
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        setPage(data);

        if (data && data.gallerijFotoCollection) {
          const mediaObjects = data.gallerijFotoCollection.items.map((item) => {
            const isVideo = item.afbeelding1[0]?.url.endsWith(".mp4") || item.afbeelding1[0]?.url.endsWith(".mov");
            return {
              src: item.afbeelding1[0]?.url || "",
              width: item.afbeelding1[0]?.width || (isVideo ? 1280 : 800),
              height: item.afbeelding1[0]?.height || (isVideo ? 720 : 600),
              type: isVideo ? "video" : "image",
              poster: isVideo ? `https://placehold.co/1280x720/ADF802/FFFFFF?text=Video` : null,
              alt: item.gallerijFotoTitel || "Media",
            };
          });

          // Filter out invalid media (e.g., missing URLs)
          setPhotos(mediaObjects.filter((media) => media.src));
        }
      });
  }, []);

  if (!page) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p>Loading...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <main>
      <div className="container">
        {/* Header Section */}
        <div className="row align-items-center pb-xlarge">
          <div className="col-12 col-md-6">
            <a href="/">
              <figure>
                <Logo className="site-logo" />
              </figure>
            </a>
          </div>
          <div className="col-12 col-md-6">
            {page?.navigationCollection?.items?.[0] ? (
              <Navigation navProps={page.navigationCollection.items[0]} />
            ) : (
              <p>Loading navigation...</p>
            )}
          </div>
        </div>

        {/* Gallery Section */}
        <div className="row pb-xlarge">
          <div className="col-12 galery">
            {photos.length > 0 ? (
              <>
                {/* Rows Photo Album */}
                <RowsPhotoAlbum
                  photos={photos.map((item, key) =>
                    item.type === "video"
                      ? { ...item, src: `https://placehold.co/1280x720/ADF802/021808?text=Video+${key}` } // Show video poster in the album
                      : item // Show image as-is
                  )}
                  targetRowHeight={150}
                  onClick={({ index: current }) => setIndex(current)}
                />


                {/* Lightbox */}
                <Lightbox
                  plugins={[Video]}
                  slides={photos.map((item, key) =>
                    item.type === "video"
                      ? {
                          type: "video",
                          width: item.width,
                          autoPlay: true,
                          muted: true,
                          playsInline: true,
                          height: item.height,
                          poster: item.poster || `https://placehold.co/1280x720/ADF802/021808?text=Video+${key}`, // Fallback to default poster
                          sources: [
                            {
                              src: item.src,
                              type: "video/mp4", // Adjust type based on your videos
                            },
                          ],
                        }
                      : { src: item.src, alt: item.alt }
                  )}
                  open={index >= 0}
                  close={() => setIndex(-1)}
                  index={index}
                />
              </>
            ) : (
              <p>No media available</p>
            )}
          </div>
        </div>

        {/* Footer Section */}
        <footer className="footer">
          <div>
            <p>&copy; 2023 Boefferke. All rights reserved.</p>
          </div>

          <div className="d-flex">
            <ul className="menu-list">
              <li><a href="https://boefferke.be/public_files/Algemene-voorwaarden.pdf">Algemene voorwaarden</a></li>
            </ul>
          </div>
        </footer>
      </div>
    </main>
  );
};

export default GalleryPage;