import Logo from "../../assets/img/svg/logo.svg";
import Navigation from '../../Navigation';
import FormuleSoort from "./FormuleSoort";
import useSWR from 'swr';
import { useState, useEffect, useCallback, useRef } from "react";
import { Spinner } from '@contentful/f36-spinner';
import { createClient } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Aos from 'aos';
import MatchHeight from 'matchheight';
import { useSearchParams } from "react-router-dom";

const Formules = () => {
  const [page, setPage] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const [matchHeight, setMatchheight] = useState(new MatchHeight());
  // the parentState will be set by its child slider component
  const [parentState, setParentState] = useState('');
  const [loadedCards, setLoadedCards] = useState(false);
  const [infoButton, setInfoButton] = useState('Info & Voorwaarden');
  const [showInfo, setShowInfo] = useState(0);
  const [animationClasses, setAnimationClasses] = useState('hide-animation');
  const ref = useRef(null);
  const [prevHeight, setPrevHeight] = useState(0);
  const [limit, setLimit] = useState(25);
  const [mqMobile, setMqMobile] = useState(window.matchMedia("(max-width: 576px)"));
  const [query, setQuery] = useState(
    `
{
  navigationCollection {
    items {
      home
      offerte
      formule
      gallerij
      contact
    }
  }
  formuleCollection(order: order_ASC, limit:${limit}) {
    total
    items {
      productImage
      productTitle
      productPrice
      productDescription
      formuleSoortCollection {
        items {
          formuleSoortTitel
        }
      }
    }
  }
  formuleSoortCollection(order: order_ASC) {
    items {
      formuleSoortTitel
      formuleSoortBeschrijving {
        json
      }
      formuleSoortAfbeelding {
        url
      }
    }
  }
}
`
  );

  Aos.init();

  // make wrapper function to give child
  const wrapperSetParentState = useCallback(val => {
    setLoadedCards(false);
    setParentState(val);
  }, [setParentState]);

  useEffect(() => {
    if(window.location.search) {
      console.log(searchParams.get("formule"));
      setParentState(searchParams.get("formule"));
    } else {
      window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.CONTENTFUL_DELIVERY_TOKEN}`,
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        setPage(data);
        setLoadedCards(true);
        new MatchHeight();
      });
    }
  }, []);

  useEffect(() => {
    setLoadedCards(true);
    setLimit(100);
    if (parentState !== '') {
      console.log(parentState);
      setSearchParams({'formule':parentState});
      window
        .fetch(`https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.CONTENTFUL_DELIVERY_TOKEN}`,
          },
          body: JSON.stringify({query :
            `{
              navigationCollection {
                items {
                  home
                  offerte
                  formule
                  gallerij
                  contact
                }
              }
              formuleCollection(order: order_ASC, limit:${limit}, where: { formuleSoort: { formuleSoortTitel_contains: "${parentState}" } }) {
                total
                items {
                  productImage
                  productTitle
                  productPrice
                  productDescription
                  formuleSoortCollection {
                    items {
                      formuleSoortTitel
                    }
                  }
                }
              }
              formuleSoortCollection(order: order_ASC) {
                items {
                  formuleSoortTitel
                  formuleSoortBeschrijving {
                    json
                  }
                  formuleSoortAfbeelding {
                    url
                  }
                }
              }
            }
            `
        }),
        })
        .then((response) => response.json())
        .then(({ data, errors }) => {
          if (errors) {
            console.error(errors);
          }
  
          setPage(data);
          setLoadedCards(true);
          if (mqMobile.matches) { // If media query matches
            document.querySelector('.js-scroll-into-view').scrollIntoView({ block: 'start',  behavior: 'smooth' });
          }
        });
    }
    setTimeout(() => {
      matchHeight.update();
    }, 500);
  }, [parentState]);

  useEffect(() => {
    if (showInfo === 1) {
      setAnimationClasses('hide-animation show');

      window.addEventListener("scroll", () => {
        if (showInfo === 1) {
          setShowInfo(0);
          setInfoButton('Info & Voorwaarden');
        }
      });
    } else {
      setAnimationClasses('hide-animation');
    }
  }, [showInfo]);

  const resetOnClick = () => {
    setLoadedCards(false);
    setParentState('');
    window
        .fetch(`https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.CONTENTFUL_DELIVERY_TOKEN}`,
          },
          body: JSON.stringify({ query }),
        })
        .then((response) => response.json())
        .then(({ data, errors }) => {
          if (errors) {
            console.error(errors);
          }
  
          setPage(data);
          setLoadedCards(true);
          if (mqMobile.matches) { // If media query matches
            document.querySelector('.js-scroll-into-view').scrollIntoView({ block: 'start',  behavior: 'smooth' });
          }
        });
  }

  if (!page) {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>Loading...</p>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <main>
      <div className="container">
        <div className="row align-items-center pb-xlarge">
          <div className="col-12 col-md-6">
            <a href="/">
              <figure>
                <Logo className="site-logo" />
              </figure>
            </a>
            </div>
            <div className="col-12 col-md-6">
              <Navigation
                navProps={page.navigationCollection.items[0]}
              ></Navigation>
            </div>
          </div>
          <div className="row pb-xlarge">
            <div className="col-12 col-xl-4 mb-5 mb-xl-0">
              <div className="row gy-2 gx-2">
                <div className="col-12"><button className="btn btn--ghost" onClick={resetOnClick}>Reset</button></div>
                {page.formuleSoortCollection.items.map((soortItem, indexFormuleSoort) =>
                  <FormuleSoort
                    formuleSoort={soortItem}
                    key={indexFormuleSoort}
                    parentState={parentState}
                    parentStateSetter={wrapperSetParentState}
                  ></FormuleSoort>
                )}
              </div>
            </div>
            <div className="col-12 col-xl-8 js-scroll-into-view">
              <div className="row gy-4 gx-4">
                {!loadedCards &&
                  <div className="col-12">
                    <Spinner size="large" />
                  </div>
                }
                <div className="col-12">
                  {
                    parentState === '' ? <p>Hier ziet u alvast een selectie van 50 formules! Wilt u meer zien klik op een van de formules aan de zijkant!</p> : null
                  }
                {page.formuleSoortCollection.items.map((cardHeader, indexCardHeader) => {
                  function createMarkupDesc() {
                    return {
                      __html: documentToHtmlString(cardHeader.formuleSoortBeschrijving.json) };
                  };

                  if (parentState && cardHeader.formuleSoortTitel === parentState) {
                    return <div className="card__header" key={indexCardHeader}>
                            {cardHeader !== null && cardHeader.formuleSoortAfbeelding !== null && !cardHeader.formuleSoortAfbeelding.url.includes('video') &&
                              <>
                                <div className="card__header__img__overlay"></div>
                                <img src={cardHeader.formuleSoortAfbeelding.url} alt={cardHeader.formuleSoortTitel} />
                              </>
                            }
                            <div className="card__header__content">
                              <h2>{cardHeader.formuleSoortTitel}</h2>
                              {cardHeader !== null && cardHeader.formuleSoortBeschrijving &&
                              <>
                                <div className="d-flex justify-content-between align-items-center">
                                  <p>Wil je meer info zoals startkost en nice to knows?</p>
                                  <button className="btn btn--green" onClick={() => {
                                  if (showInfo !== 1) {
                                    setShowInfo(1);
                                    setInfoButton('Sluiten');
                                  } else {
                                    setShowInfo(0);
                                    setInfoButton('Info & Voorwaarden');
                                    Aos.init();
                                  }
                                }}>
                                  {infoButton}
                                </button>
                                </div>
                                <div ref={ref} className={animationClasses} dangerouslySetInnerHTML={createMarkupDesc()}></div>
                              </>
                              }
                            </div>
                          </div>
                  } else {
                    return null
                  }
                }
                )}
                </div>
                {loadedCards && page.formuleCollection.items.map((formuleItem, indexFormuleItem) =>{
                  if(parentState !== '' && parentState === formuleItem.formuleSoortCollection.items[0]?.formuleSoortTitel) {
                    return <div className='col-12 col-sm-6' data-aos="fade-up" data-aos-anchor-placement="top-bottom" key={indexFormuleItem}>
                            <div className="card">
                              <div className="card__image__wrapper" data-mh>
                                {formuleItem.productImage !== null && !formuleItem.productImage[0]?.secure_url.includes('video') &&
                                <img src={formuleItem.productImage[0]?.secure_url} alt={formuleItem.productTitle} />
                                }
                                {formuleItem.productImage !== null && formuleItem.productImage[0]?.secure_url.includes('video') &&
                                  <video className="test" src={formuleItem.productImage[0]?.secure_url} autoPlay playsInline controls muted></video>
                                }
                              </div>
                              <div className="card__content">
                                <div className="card__content__title" data-mh>
                                    <h2>{formuleItem.productTitle}</h2>
                                </div>
                                <p data-mh>{formuleItem.productDescription}</p>
                                <a className="stretched-link" href="#" onClick={() => {setParentState(formuleItem.formuleSoortCollection.items[0].formuleSoortTitel)}}></a>
                                <div className="d-lg-flex justify-content-between">
                                  <div className="card__content__price text-black">
                                    {formuleItem.productPrice}
                                  </div>
                                  <button className="btn mt-3 mt-lg-0 btn--ghost" onClick={() => {
                                    if (showInfo !== 1) {
                                      setShowInfo(1);
                                      setInfoButton('Sluiten');
                                    } else {
                                      setShowInfo(0);
                                      setInfoButton('Info & Voorwaarden');
                                      Aos.init();
                                    }
                                  }}>
                                    {infoButton}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                  } else if (parentState == '') {
                    return <div className='col-12 col-sm-6' data-aos="fade-up" data-aos-anchor-placement="top-bottom" key={indexFormuleItem}>
                            <div className="card">
                              <div className="card__image__wrapper" data-mh>
                                {formuleItem.productImage !== null && !formuleItem.productImage[0]?.secure_url.includes('video') &&
                                <img src={formuleItem.productImage[0]?.secure_url} alt={formuleItem.productTitle} />
                                }
                                {formuleItem.productImage !== null && formuleItem.productImage[0]?.secure_url.includes('video') &&
                                  <video src={formuleItem.productImage[0]?.secure_url} autoPlay playsInline controls muted></video>
                                }
                              </div>
                              <div className="card__content">
                                <div className="card__content__title" data-mh>
                                    <h2>{formuleItem.productTitle}</h2>
                                </div>
                                <p data-mh>{formuleItem.productDescription}</p>
                                <div className="card__content__price text-black">
                                  {formuleItem.productPrice}
                                </div>
                                <a className="stretched-link" href="#" onClick={() => {setParentState(formuleItem.formuleSoortCollection.items[0].formuleSoortTitel)}}></a>
                              </div>
                            </div>
                          </div>
                  } else {
                    return null
                  }
                }
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
            <footer className='footer'>
              <div>
                  <p>&copy; 2023 Boefferke. All rights reserved.</p>
              </div>
              
              <div className='d-flex'>
                  <ul className='menu-list'>
                      <li><a href="https://boefferke.be/public_files/Algemene-voorwaarden.pdf">Algemene voorwaarden</a></li>
                  </ul>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Formules
