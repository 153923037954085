import useSWR from 'swr'
import { useState } from 'react'
import { Spinner } from '@contentful/f36-spinner'
import { createClient } from 'contentful'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import HomeInfo from './HomeInfo'
import Aos from 'aos';

const client = createClient({
  space: process.env.CONTENTFUL_SPACE_ID,
  accessToken: process.env.CONTENTFUL_DELIVERY_TOKEN,
})

const fetcher = async () => {
  const entryItems = await client.getEntries({ content_type: 'homepagina' })

  // Process the data from the Contentful REST API into a neater object
  // If you want to avoid this step, consider using the GraphQL API
  const entries = entryItems.items.map((entry) => {

    const { fields } = entry

    return {
      name: fields.paginaTitle,
      info: documentToHtmlString(fields.info),
      img: fields.homeImage,
      imgHead: documentToHtmlString(fields.imageContent),
      imgDesc: documentToHtmlString(fields.imageContentDescription),
      secondInfo: documentToHtmlString(fields.meerInfo),
      nav: fields.navigatie.fields,
      formules: fields.formules,
    }
  })

  return { entries }
}

function App() {
  const { data, error } = useSWR('contentful', fetcher)
  Aos.init();

  if (error) {
    console.log(error)
    return <div>failed to load </div>
  }
  if (!data) return <Spinner size="large" />

  const { entries } = data

  const paintings = entries
    .map(({ name, info, nav, img, imgHead, imgDesc, formules, secondInfo }, i) => {
      return (
        <div key={i} className="container">
          <HomeInfo
            name={name}
            img={img}
            imgHead={imgHead}
            imgDesc={imgDesc}
            formulesOverview={formules}
            info={info}
            moreInfo={secondInfo}
            nav={nav}
          ></HomeInfo>
        </div>
      )
    })

  return (
    <main>
      <div>{paintings}</div>
    </main>
  )
}

export default App
